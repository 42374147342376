import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入公共样式
import './static/css/index.css'
// 阿里巴巴矢量图标库
import './static/fonts/iconfont.css'
// 引入element组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
//引入axios
import axios from "axios";
Vue.prototype.$axios = axios;
// axios.defaults.baseURL = 'http://192.168.19.82';
axios.defaults.baseURL = '/';
// axios.defaults.baseURL = 'http://test.002646.com';
//axios.defaults.baseURL = 'http://002646.com';

import VueSlideUpDown from 'vue-slide-up-down'
Vue.component('vue-slide-up-down', VueSlideUpDown)

// import timelineCarousel from 'timeline-carousel'
// Vue.use(timelineCarousel)

// 引入懒加载插件
import VueLazyload from 'vue-lazyload'
// 注册插件
Vue.use(VueLazyload,{
  preLoad: 1.3,
  // loading: 'https://www.keaidian.com/uploads/allimg/190424/24110307_8.jpg',
  attempt: 1
})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// 点击路由跳转到页面最顶端
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0

  // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
  if (to.matched.length === 0) {
    from.path ? next({ name: from.name }) : next('*')
  }
  next()
})
Vue.filter('subString', function (value) {
  if (value != "" && value.length > 10) {
    return value.substr(0, 10);
  } else {
    return value;
  }
})
// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })
/* // 加签验签
import { signUtil, aesUtil, rsaUtil, servePublicKey, privateKey } from '@/encrypt'
axios.defaults.timeout = 20000;
axios.defaults.baseURL = 'http://192.168.19.82'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

// 请求拦截器
axios.interceptors.request.use((config) => {
  // other code here...  

  if (config.data) {
    // 生成签名
    const signKey = signUtil(config.data)
    const newSignKey = rsaUtil.ensign(signKey, privateKey)


    // AES随机秘钥
    const romkey = aesUtil.genKey()


    // 服务端公钥对随机秘加密
    const aesKey = rsaUtil.encrypt(romkey, servePublicKey)


    // AES + 随机秘对data体加密
    config.data['sign'] = newSignKey
    const aesData = aesUtil.encrypt(config.data, romkey)


    const newData = { data: aesData, key: aesKey }
    config.data = newData
  }


  return config;
}, (error) => {
  return Promise.reject(error);
});




// 响应拦截器
axios.interceptors.response.use((res) => {
  // other code here

  const { data, key } = res.data
  // 重新生成签名验证
  if (newData.sign) {
    const copyData = JSON.parse(JSON.stringify(newData))
    delete copyData.sign
    const data = signUtil(copyData)
    const flag = rsaUtil.design(data, newData.sign, servePublicKey)
    if (!flag) { return Promise.reject({ message: '签名失败' }) }
    return Promise.resolve(res.data)
  } else {
    return Promise.reject({ message: '请求异常' })
  }

}, (error) => {
  return Promise.reject(error);
});
 */