<template>
  <div class="block">
    <el-carousel
      @change="swiperChange"
      :height="carouselHeight + 'px'"
      :autoplay="carouselAutoPlay"
      :arrow="arrow"
      :interval="5000"
    >
      <!-- 轮播切换时间 -->
      <!-- :interval="5000" -->
      <el-carousel-item v-for="item in toLunBo" :key="item.id" ref="ele_item">
        <!-- <div v-if="item.type == 'article'"> -->
        <router-link
          v-if="item.type == 'article'"
          :to="'/news/newDetails/' + item.id"
          target="_blank"
        >
          <img v-lazy="item.image" alt="" ref="LunBoImg" />
        </router-link>
        <!-- </div> -->
        <!-- <div v-if="item.type == 'goods'"> -->
        <router-link
          v-if="item.type == 'goods'"
          :to="'/product/details_' + item.id"
          target="_blank"
        >
          <img v-lazy="item.image" alt="" ref="LunBoImg" />
        </router-link>
        <div v-if="item.type == 'beida'" @click="goPku('/pku', 'pku_play')">
          <img v-lazy="item.image" alt="" ref="LunBoImg" />
        </div>
        <!-- </div> -->
        <div v-if="item.type == 'default'" class="swiper_default">
          <img
            v-if="item.material_type == '2'"
            v-lazy="item.image"
            alt=""
            ref="LunBoImg"
          />
          <video
            muted
            autoplay
            class="myVideo"
            loop
            v-if="item.material_type == '1'"
          >
            <!-- 进度条 -->
            <!-- controls -->
            <source :src="item.video" />
          </video>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    toLunBo: Array,
  },
  data() {
    return {
      carouselAutoPlay: true,
      arrow: "always",
      carouselHeight: 0,
      screenWidth: 0,
      // 跳转到的页面路由
      goUrl: "",
    };
  },
  methods: {
    // 幻灯片切换时触发
    swiperChange(current, pre) {
      // 当前幻灯片后台接口数据
      let ele_list = this.toLunBo[current];
      // 判断当前幻灯片是否为视频
      if (ele_list.type == "default" && ele_list.material_type == "1") {
        // console.log('当前是视频')
        // console.log(current);
        // 获取当前幻灯片元素 重新播放
        this.$refs.ele_item[current].$el.querySelector("video").load();
      }
    },
    //点击导航栏跳转到指定位置
    goPku(path, id) {
      var path = path;
      var Id = id;
      // 将每个模块点击的id存储到本地 方便服务中心组件获取
      localStorage.setItem("toPku", Id);
      // 跳转至北大页面
      this.$router.push(path);
      // 跳转至北大指定模块
      // let top = document.getElementById(id).offsetTop;
      // window.scrollTo({
      //   top: top - 96,
      // });
    },
  },
  mounted() {
    // console.log(this.toLunBo);
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.carouselHeight = (885 / 1920) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.carouselHeight = (885 / 1920) * this.screenWidth;
    };
  },
};
</script>

<style lang="less" scoped>
.block {
  // min-width: 1200px;
  margin-top: 96px;
}

.el-carousel__item img {
  width: 100%;
  // min-height: 617px;
  height: 100%;
  vertical-align: bottom;
}

@media screen and (max-width: 399px) {
  /deep/ .el-carousel__button {
    width: 20px;
  }
}

.swiper_video {
  width: 100%;

  video {
    width: 100%;
  }
}

.swiper_default {
  width: 100%;
  height: 100%;

  .myVideo {
    width: 100%;
    height: 100%;
  }
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/deep/ .el-carousel__arrow {
  width: 48px;
  height: 96px;
  // border-radius: 0px 5px 5px 0px;
  background-color: rgba(0, 0, 0, 0.3);

  @media screen and (min-width: 767px) and (max-width: 991px) {
    width: 36px;
    height: 74px;
  }

  @media screen and (max-width: 767px) {
    width: 24px;
    height: 48px;
  }

  i {
    font-size: 26px;

    @media screen and (min-width: 767px) and (max-width: 991px) {
      font-size: 22px;
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
}

/deep/ .el-carousel__arrow--left {
  left: 0;
  border-radius: 0px 5px 5px 0px;
}

/deep/ .el-carousel__arrow--right {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

/* 文字 */
.item_list {
  display: flex;
  justify-content: center;
}

.swiper_text {
  color: #fff;
  width: 1200px;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: calc(25vh);
  z-index: 888;

  .swiper_title {
    font-size: 100px;
    cursor: pointer;
  }

  .swiper_msg {
    font-size: 45px;
    padding: 30px 0 50px 0;
    cursor: pointer;
  }

  .swiper_btn {
    width: 265px;
    height: 100px;
    background-color: #fba919;
    color: #fff;
    font-size: 28px;
    border: 0;
  }
}

//视频
.swiper_zzc {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  .swiper_video {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .swiper_videoall {
      width: 60%;
      position: relative;
    }

    video {
      width: 100%;
      height: 100%;
      vertical-align: bottom;

      source {
        width: 100%;
        height: 100%;
        vertical-align: bottom;
      }
    }

    i {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 30px;
      color: rgb(233, 229, 229);
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
